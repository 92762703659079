import React from "react";
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image';
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import styled from 'styled-components';
import { Section } from '../styles/common/Common';
import Hero from "../components/Hero";
import SEO from "../components/SEO";
import { BreadcrumbWrapper } from "../styles/common/Element";
import { Col, Container, Row } from "../styles/common/Layout";

const ImageWrapper = styled.div`
  margin-bottom: 3rem;
`;

const ImpressumPage = ({ pageContext, data }) => {
  const { settings } = data;
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <div>
      <SEO
        title={"Impressum"}
      />
      <Hero slug={'startseite'} small>
        <h1 className="headline">Impressum</h1>
      </Hero>
      <Container as="section">
        <Row>
          <Col>
            <BreadcrumbWrapper>
              <Breadcrumb crumbs={crumbs} crumbSeparator=" / " crumbLabel={'Impressum'} />
            </BreadcrumbWrapper>
          </Col>
        </Row>
        <Row className="f-direction-col">
          <Col>
            <address>
              <strong><span>Physiotherapie Stefan Dumpe</span></strong> <br />
              Sylvesterstr. 6 <br />
              46348 Raesfeld <br />
              Deutschland <br />
            </address>
            <p>
              Tel.:{" "}
              <a
                className='is-text'
                href='tel:+49028652026291'
                data-rel='external'>
                +49-02865-2026291
              </a>{" "}
              <br />
              E-Mail:{" "}
              <a href='mailto:info@physiotherapie-dumpe.de'>info@physiotherapie-dumpe.de</a>
            </p>
            <p>
              <strong>Berufsbezeichnung und berufsrechtliche Regelungen</strong><br />
              Berufsbezeichnung: Staatlich anerkannter Physiotherapeut<br />
              Verliehen durch: Bundesrepublik Deutschland<br />
              Es gelten folgende berufsrechtliche Regelungen: Gesetz über die Berufe in der Physiotherapie (Masseur- und Physiotherapeutengesetz - MPhG)
              Regelungen einsehbar unter: <a target="_blank" rel='noopener noreferrer' href="http://www.gesetze-im-internet.de/mphg">http://www.gesetze-im-internet.de/mphg</a>
            </p>
            <p>
              <strong>Umsatzsteuer-Identifikationsnummer gemäß § 27 a
                Umsatzsteuergesetz:</strong><br /> Steuerfreie Leistungen §4 Nr. 14 UStG
            </p>
            <p>
              <strong>Aufsichtsbehörde:</strong><br />
              Gesundheitsamt Kreis Borken
            </p>
            <p>
              <strong>Sozialrechtliche Zulassung unter dem Institutionskennzeichen:</strong><br />
              In Bearbeitung / Folgt<br />
              <br />
              <strong>Berufsrechtliche Regelung:</strong><br />
              Gesetz über den Beruf der Physiotherapeutin und des Physiotherapeuten.<br />
              <br />
              <strong>Weiterführende Informationen:</strong><br />
              <a target="_blank" rel='noopener noreferrer' href="https://www.ifk.de/verband/beruf/berufsbild-berufsgesetz/">https://www.ifk.de/verband/beruf/berufsbild-berufsgesetz/</a>
            </p>
            <p>
              Plattform der EU-Kommission zur Online-Streitbeilegung:{" "}
              <a
                href='https://ec.europa.eu/consumers/odr'
                target='_blank'
                rel='noopener noreferrer'>
                https://ec.europa.eu/consumers/odr
              </a>{" "}
              <br />
              <br />
              Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor
              einer Verbraucherschlichtungsstelle weder verpflichtet noch
              bereit.
            </p>
            <p>
              <strong>Layout, Design und Programmierung:</strong><br />
              Marek Maras - Frontend Webentwicklung<br />
              <a
                href='mailto:mail@marek-maras.de'
                target='_blank'
                rel='noopener noreferrer'>
                mail@marek-maras.de
              </a><br />
              <a
                href='https://marek-maras.de'
                target='_blank'
                rel='noopener noreferrer'>
                www.marek-maras.de
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default ImpressumPage;

export const query = graphql`
  query SettingsImpressumQuery {
    settings: sanityStoreSettings {
      name
      image {
        asset {
          gatsbyImageData(width: 128)
        }
      }
    }
  }
`;